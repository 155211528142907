export enum DealPropertyConnectionType {
    ELECTRICTIY = "Strom",
    WATER = "Wasser/Brunnen",
    GAS = "Gas",
    SEWER = "Kanal",
    DISTRICT_HEATING = "Fernwärme",
    PHOTOVOLTAICS = "Photovoltaik",
    OTHER = "sonstiges:",
}

export enum DealPropertyParcelType {
    MIDDLE = "Mittelparzelle",
    CORNER = "Eckparzelle",
    FLAG = "Fahnenparzelle",
}

export enum DealPropertyInclinationType {
    EVEN = "eben",
    INCLINED = "geneigt",
    STEEP = "steil",
}

export enum DealPropertyEnvironmentalImpactType {
    NONE = "keine vorhanden",
    EXISTS = "störende Einflüße:",
}

export enum DealPropertyBuildingMaterialType {
    NO_ENDANGERMENT = "Keine Gefährdung",
    LOW_ENDANGERMENT = "geringe Gefährdung",
    HIGH_ENDANGERMENT = "starke Gefährdung",
}

export enum DealPropertyCondtitionType {
    NORMAL = "normal",
    GOOD = "gut",
    USED = "abgewohnt",
    VACANT = "Objekt ist leerstehend --> seit ... Jahren",
    NOT_HABITABLE = "Objekt nicht bewohnbar",
}

export enum DealPropertyEntireHouseConditionType {
    BACKLOG = "Offensichtlicher Erhaltungsrückstau",
    CONTINUOUSLY_RENOVATED = "wird laufend saniert",
    NORMAL = "normal",
    NEW = "neuwertig",
}

export enum DealPropertyGarageSpaceType {
    RENT = "Miete",
    OWNED = "Eigentum",
}

export enum DealPropertyParkingSpaceType {
    RENT = "Miete",
    OWNED = "Eigentum",
}
