export const ApplicationColors = {
    main_color: "#5c4df6",
    main_color_light: "#8080ff",
    main_color_pale: "#f5f4ff",
    main_color_lighter: "#f1f1f1",
    main_color_medium_light: "#a3a3ff",
    main_color_low_light: "#e1e1ff",
    main_color_dark: "#0000b3",
    main_color_darker: "#000030",
    main_color_light_bg: "#fbfdff",
    default_scroll_track: "#d8d6d6",
    default_scroll_thumb: "#959797",
    neutral_yellow: "#fdc827",
    success_green: "#99ec68",
    white: "#ffffff",
    black: "#000000",
    login_bg: "#fbfdff",
    light_gray: "#f1f1f1",
    error_light: "#f25e5e",
    error_bright: "#fb2626",
    success_green_bright: "#49fb26",
    success_green_dark: "#378d26",
    survey_grey: "#eeeef0",
    survey_tooltip: "#4a4a84",
    survey_tooltip_text: "#f6f6ff",
    sidebar_top_section_bg: "#141421",
    sidebar_item_selected_bg: "#efedfc",
    sidebar_item_color: "#474747",
    sidebar_name_color: "#cfb876",
    gray_accent_light: "#fafafa",
    gray_disabled: "#808080",
};
