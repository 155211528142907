export enum DealPropertiesSection {
    FINANCING_OBJECT = "financing_object",
    EXISTING_OBJECT_1 = "existing_object_1",
    EXISTING_OBJECT_2 = "existing_object_2",
    EXISTING_OBJECT_3 = "existing_object_3",
    EXISTING_OBJECT_4 = "existing_object_4",
    PERSONAL_BORROWER_1 = "personal_borrower_1",
    PERSONAL_BORROWER_2 = "personal_borrower_2",
    PERSONAL_BORROWER_3 = "personal_borrower_3",
    PERSONAL_BORROWER_4 = "personal_borrower_4",
    FINANCING_REQUESTS = "financing_requests",
    PROJECT_COSTS = "project_costs",
    FINANCING_COSTS = "financing_costs",
    FUNDING_DETAILS = "funding_details",
    OPINION = "opinion",
    REFERRAL = "referral",
    BANK_SPECIFIC_DATA = "bank_specific_data",
    FINANCING_DETAILS = "financing_details",
    BUDGET = "budget",
}

export enum DealPropertiesBankSection {
    FINANCING_OBJECT = "financing_object",
    FINANCING_DETAILS = "financing_details",
    PERSONAL_BORROWER_1 = "personal_borrower_1",
    PERSONAL_BORROWER_2 = "personal_borrower_2",
    HOUSEHOLD_INCOME = "household_income",
    HOUSEHOLD_EXPENSES = "household_expenses",
    PROJECT_COSTS = "project_costs",
}

export enum SalariesPerYearValues {
    SALARIES_12 = 12,
    SALARIES_14 = 14,
}

export enum DealPropertiesSectionId {
    CUSTOMER_REACHED = "customerReached",
    GENERAL_INFORMATION = "generalInformation",
    PROJECT_COSTS = "projectCosts",
    ESTIMATION_DATA = "estimationData",
    ESTIMATION = "estimation",
    LTV = "ltv",
    EXISTING_OBJECTS = "existingObjects",
    EXISTING_OBJECTS_FOR_MORTGAGE = "existingObjectsForMortgage",
    INCOME_AND_LIABILITIES = "incomeAndLiabilities",
    INCOME_AND_LIABILITIES_CUSTOMER = "incomeAndLiabilitiesCustomer",
    INCOME = "income",
    LIABILITIES = "liabilities",
    DSTI = "dsti",
    PERSONAL_INFORMATION = "personalInformation",
    HOUSEHOLD_DATA = "householdData",
    FINANCING_REQUESTS = "financingRequests",
    NEXT_STEPS = "nextSteps",

    LAND_PLOT = "landPlot",
    PROPERTY = "property",
    CONSTRUCTION = "construction",

    SURVEY_INFORMATION = "surveyInformation",
    COMPETITION_DATA = "competitionData",
    FINANCING_OBJECT = "financingObject",

    INTERIM_FINANCING = "interimFinancing",
}
