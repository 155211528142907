import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { config } from "src/config";
import { ApplicationRoute } from "src/shared/types/route";
import { AuthContext } from "../../../context/AuthContext";
import { useTranslation } from "../../../context/LocaleContext";
import { ApplicationColors } from "../../../shared/style/global";
import { LoginStatus } from "../../../shared/types/user";
import { Logo } from "../../components/logo/Logo";
import { CustomerLoginPage } from "./CustomerLoginPage";
import { Column, LogoWrapper, Row, Wrapper } from "./styles";
import "./style.scss";
import { ControlledTextField } from "src/ui/components/formControls/ControlledTextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { OneInRow } from "src/ui/components/forms/styles/formControlSizes";
import { CredentialsLoginData } from "src/service/google/firebase";
import googleLogo from "src/assets/img/google.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

const AUTO_LOGIN_PAGES: ApplicationRoute[] = [
    ApplicationRoute.SURVEY,
    ApplicationRoute.TOP_OFFER,
    ApplicationRoute.ADDITIONAL_INFO,
];

const credentialsSchema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().min(1).required(),
});

const LoginPage = () => {
    const { login, loginStatus, loginViaTokenError, loginErrorCode } = useContext(AuthContext);
    const form = useForm({
        resolver: yupResolver(credentialsSchema),
        defaultValues: {
            email: "",
            password: "",
        },
    });
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const showCustomerLogin =
        config.environment !== "development" && AUTO_LOGIN_PAGES.includes(pathname as ApplicationRoute);
    const [loginStatusMessage, setLoginStatusMessage] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);

    const loginWithGoogle = async () => {
        await login({ strategy: "google" });
    };

    const loginWithCredentials = async (data: CredentialsLoginData) => {
        await login({ strategy: "credentials", data });
    };

    useEffect(() => {
        switch (loginStatus) {
            case LoginStatus.ERROR:
                setLoginStatusMessage(t("login_status_message_error"));
                break;
            case LoginStatus.UNAUTHORIZED:
                setLoginStatusMessage(t("login_status_message_unauthorized"));
                break;
        }
    }, [loginStatus, t]);

    if (showCustomerLogin) {
        return <CustomerLoginPage loginError={loginViaTokenError} />;
    }

    return (
        <Wrapper>
            <Column>
                <Row>
                    <LogoWrapper>
                        <Logo width="100px" />
                        <Typography
                            width="100%"
                            color="secondary"
                            fontWeight={"bold"}
                            variant="h6"
                            textTransform="uppercase"
                            textAlign="left"
                        >
                            mortgaging tool
                        </Typography>
                    </LogoWrapper>
                </Row>
                <Row
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 4,
                    }}
                >
                    <Box>
                        <Typography sx={{ fontSize: "32px", lineHeight: 1.25 }}>
                            {t("page.signIn.form.title")}
                        </Typography>
                        <Typography sx={{ fontSize: "14px", marginTop: 1 }}>
                            {t("page.signIn.form.description")}
                        </Typography>
                    </Box>
                    <Box>
                        <form {...form} onSubmit={form.handleSubmit(loginWithCredentials)}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                <Box>
                                    <ControlledTextField
                                        control={form.control}
                                        name="email"
                                        label={t("page.signIn.credentialsForm.email.label")}
                                        type="email"
                                        sx={OneInRow}
                                    />
                                </Box>
                                <Box>
                                    <ControlledTextField
                                        control={form.control}
                                        name="password"
                                        label={t("page.signIn.credentialsForm.password.label")}
                                        type={passwordVisible ? "text" : "password"}
                                        sx={OneInRow}
                                        InputProps={{
                                            endAdornment: passwordVisible ? (
                                                <VisibilityOff
                                                    sx={{ color: grey[700], cursor: "pointer" }}
                                                    onClick={() => setPasswordVisible(false)}
                                                />
                                            ) : (
                                                <Visibility
                                                    sx={{ color: grey[700], cursor: "pointer" }}
                                                    onClick={() => setPasswordVisible(true)}
                                                />
                                            ),
                                        }}
                                    />
                                </Box>
                                <LoadingButton
                                    loading={loginStatus === LoginStatus.LOADING}
                                    variant="contained"
                                    sx={{ gap: 1, height: "40px" }}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                >
                                    <Typography> {t("login")}</Typography>
                                </LoadingButton>
                                <Box>
                                    {loginErrorCode && (
                                        <Typography width="90%" textAlign="center" color="error">
                                            {t(`page.signIn.errors.${loginErrorCode}`)}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </form>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Box sx={{ width: "50%", height: "1px", backgroundColor: "#eee" }} />
                        <Typography sx={{ textTransform: "uppercase" }}>{t("common.or")}</Typography>
                        <Box sx={{ width: "50%", height: "1px", backgroundColor: "#eee" }} />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <LoadingButton
                            loading={loginStatus === LoginStatus.LOADING}
                            onClick={loginWithGoogle}
                            variant="outlined"
                            sx={{ gap: 1 }}
                            fullWidth
                            size="large"
                        >
                            <img src={googleLogo} alt="Google" style={{ width: "24px" }} />
                            <Typography>{t("login_label")}</Typography>
                        </LoadingButton>
                        {loginStatusMessage && !loginErrorCode && (
                            <Typography width="90%" marginTop={3} textAlign="center" color="error">
                                {loginStatusMessage}
                            </Typography>
                        )}
                    </Box>
                    <Box>
                        <Typography sx={{ textAlign: "center" }}>
                            {t("page.signIn.noAccount")} <Link to="/signup">{t("page.signIn.signup")}</Link>
                        </Typography>
                    </Box>
                </Row>
            </Column>
            <Column sx={{ backgroundColor: ApplicationColors.main_color, color: "white" }}>
                <Row
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 4,
                    }}
                >
                    <Box>
                        <Typography sx={{ fontSize: "32px", lineHeight: 1.25 }}>
                            {t("page.signIn.marketing.title")}
                        </Typography>
                        <Typography sx={{ fontSize: "14px", marginTop: 1 }}>
                            {t("page.signIn.marketing.description")}
                        </Typography>
                    </Box>
                    <Box>
                        <Box sx={{ padding: 2, background: "white", borderRadius: "25px" }}>
                            <img src="/signin.png" alt="Sign in" style={{ width: "100%" }} />
                        </Box>
                    </Box>
                </Row>
            </Column>
        </Wrapper>
    );
};

export default LoginPage;
