import { Fragment, ReactNode, Suspense, lazy, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import "../../App.scss";
import { DealContext, DealContextProvider } from "../../context/DealContext";
import { NotificationContextProvider } from "src/context/notification/NotificationContext";
import { useSettingsContext } from "src/context/SettingsContext";
import { config } from "src/config";
import { ApplicationWrapper } from "./wrappers/ApplicationWrapper";
import { LoginStatus } from "src/shared/types/user";
import ErrorBoundaryWrapper from "../components/wrappers/error-boundary/ErrorBoundaryWrapper";
import { Outlet, useLocation } from "react-router-dom";
import { ToolErrorBoundaryContainer } from "./error-boundary/ToolErrorBoundaryContainer";
import { APPLICATION_CUSTOMER_ROUTES } from "src/shared/constants/route";
import { InitializationProvider } from "src/context/InitializationProvider";
import { ApplicationRoute } from "src/shared/types/route";
import { usePageTitle } from "src/shared/hooks/usePageTitle";
import { useTranslation } from "src/context/LocaleContext";
import CircularSpinner from "../components/spinners/CicularSpinner/CircularSpinner";
import { useJsApiLoader } from "@react-google-maps/api";
import LoginPage from "../pages/login/LoginPage";

const SidebarContainer = lazy(() => import("./SidebarContainer"));

export const ApplicationContainer = () => {
    const { pathname } = useLocation();
    const isCustomerPath = APPLICATION_CUSTOMER_ROUTES.includes(pathname);
    const { tokenLoginStatus, applicationUser } = useContext(AuthContext);
    const { appSettings } = useSettingsContext();

    // Only tool users depend and need to see these components, developers see it regardless
    // Survey users don't need it so we skip loading it in the first place
    const loadUserComponents = config.environment === "development" || !!applicationUser;

    if (pathname !== ApplicationRoute.SIGNUP && !applicationUser?.email && tokenLoginStatus !== LoginStatus.SUCCESS) {
        return <LoginPage />;
    }

    return (
        <NotificationContextProvider>
            <DealContextProvider>
                <InitializationProvider>
                    <ApplicationWrapper
                        displaySnow={config.dev.theme.holidayThemeEnabled && appSettings?.DISPLAY_SNOWFLAKES}
                        displayChristmasLights={
                            config.dev.theme.holidayThemeEnabled && appSettings?.DISPLAY_CHRISTMAS_LIGHTS
                        }
                    >
                        <PageTitle>
                            <DealLoader />
                            {loadUserComponents && (
                                <Suspense fallback={<LoggerFallback id={"USER_COMPONENTS"} />}>
                                    <GoogleLoader />
                                    <SidebarContainer />
                                </Suspense>
                            )}
                            <RoutePage isCustomerPath={isCustomerPath} />
                        </PageTitle>
                    </ApplicationWrapper>
                </InitializationProvider>
            </DealContextProvider>
        </NotificationContextProvider>
    );
};

interface LoggerFallbackProps {
    id: string;
}

function LoggerFallback({ id }: LoggerFallbackProps) {
    useEffect(() => {
        console.log(`[SUSPENSE] Suspense with id "${id}" waiting for children...`);

        return () => {
            console.log(`[SUSPENSE] Suspense with id "${id} finshed loading."`);
        };
    }, [id]);

    return <Fragment />;
}

function DealLoader() {
    const { t } = useTranslation();
    const { activeDealLoading } = useContext(DealContext);
    return (
        <CircularSpinner
            showSpinner={activeDealLoading}
            hasProgress={false}
            text={t("page.spinner.loading_deal")}
            size={200}
            percentageTextFontSize={"2em"}
            statusTextFontSize={"2em"}
        />
    );
}

function RoutePage({ isCustomerPath }: { isCustomerPath: boolean }) {
    if (isCustomerPath) {
        return <Outlet />;
    }

    return (
        <ErrorBoundaryWrapper renderFallback={ToolErrorBoundaryContainer}>
            <Outlet />
        </ErrorBoundaryWrapper>
    );
}

function GoogleLoader() {
    useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: config.google.apiKey,
    });

    return <Fragment />;
}

type PageTitleProps = {
    children: ReactNode;
};

function PageTitle({ children }: PageTitleProps) {
    const { t } = useTranslation();
    const { activeDeal } = useContext(DealContext);
    const location = useLocation();

    usePageTitle(
        location.pathname,
        {
            [ApplicationRoute.TOP_OFFER]: t("app.title.topOffer"),
            [ApplicationRoute.SURVEY]: t("app.title.survey"),
            [ApplicationRoute.ADDITIONAL_INFO]: t("app.title.survey"),
            [ApplicationRoute.HUB]: t("app.title.hub"),
        },
        activeDeal?.name ?? t("app.title.default"),
    );

    return <>{children}</>;
}
