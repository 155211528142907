import MiraclLogo from "../../../assets/img/miracl-logo.svg?react";
import { ApplicationColors } from "src/shared/style/global";

interface Props {
    fill?: string;
    width?: string;
}

export const Logo = ({ fill, width = "100%" }: Props) => {
    return <MiraclLogo fill={fill ?? ApplicationColors.main_color} width={width} className="logo" />;
};
