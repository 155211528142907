import * as Yup from "yup";
import { Shape } from "../types";
import { multiSelectValidation, numberValidation, stringOptionValidation, stringValidation } from "../util";
import {
    PROPERTY_TYPE_VALUES,
    PROPERTY_EXTERIOR_SURFACES,
    PROPERTY_BASEMENT_VALUES,
    PROPERTY_WINDOW_TYPES,
    JA_NEIN_VALUES,
} from "../../../constants";
import { ExistingPropertyEnumerator, DealProperties } from "../../../models";

const generateExistingPropertySchemaByIndex = (existingPropertyEnumerator: ExistingPropertyEnumerator) => {
    return Yup.object<Shape<Partial<DealProperties>>>({
        [`${existingPropertyEnumerator}Type`]: stringOptionValidation(PROPERTY_TYPE_VALUES),
        [`${existingPropertyEnumerator}Country`]: stringValidation(),
        [`${existingPropertyEnumerator}State`]: stringValidation(),
        [`${existingPropertyEnumerator}ZipCode`]: numberValidation(),
        [`${existingPropertyEnumerator}City`]: stringValidation(),
        [`${existingPropertyEnumerator}Address`]: stringValidation(),
        [`${existingPropertyEnumerator}IdentificationEntryNumber`]: numberValidation(),
        [`${existingPropertyEnumerator}IdentificationMunicipality`]: stringValidation(),
        [`${existingPropertyEnumerator}ConstructionYear`]: numberValidation(),
        [`${existingPropertyEnumerator}LastRenovatedYear`]: numberValidation(),
        [`${existingPropertyEnumerator}LivingSpaceArea`]: numberValidation(0),
        [`${existingPropertyEnumerator}LandArea`]: numberValidation(0),
        [`${existingPropertyEnumerator}ExteriorSurfaces`]: multiSelectValidation(PROPERTY_EXTERIOR_SURFACES),
        [`${existingPropertyEnumerator}ParkingSpots`]: numberValidation(0),
        [`${existingPropertyEnumerator}HasElevator`]: stringOptionValidation(JA_NEIN_VALUES),
        [`${existingPropertyEnumerator}BasementType`]: stringOptionValidation(PROPERTY_BASEMENT_VALUES),
        [`${existingPropertyEnumerator}Floors`]: numberValidation(0),
        [`${existingPropertyEnumerator}FloorLocation`]: numberValidation(),
        [`${existingPropertyEnumerator}WindowType`]: stringOptionValidation(PROPERTY_WINDOW_TYPES),
        [`${existingPropertyEnumerator}Value`]: numberValidation(0),
        // [`${existingPropertyEnumerator}Owner`]: stringOptionValidation(PROPERTY_OWNER_VALUES),
        [`${existingPropertyEnumerator}BalconyArea`]: numberValidation(0),
        [`${existingPropertyEnumerator}RoofTerraceArea`]: numberValidation(0),
        [`${existingPropertyEnumerator}GardenArea`]: numberValidation(0),
        [`${existingPropertyEnumerator}LoggiaArea`]: numberValidation(0),
        [`${existingPropertyEnumerator}TerraceArea`]: numberValidation(0),
        [`${existingPropertyEnumerator}WinterGardenArea`]: numberValidation(0),
        [`${existingPropertyEnumerator}CommercialUse`]: numberValidation(0),
    });
};

export const existingProperty1Schema = generateExistingPropertySchemaByIndex("existingProperty1");
export const existingProperty2Schema = generateExistingPropertySchemaByIndex("existingProperty2");
export const existingProperty3Schema = generateExistingPropertySchemaByIndex("existingProperty3");
export const existingProperty4Schema = generateExistingPropertySchemaByIndex("existingProperty4");

export const existingPropertiesSchemas = [
    existingProperty1Schema,
    existingProperty2Schema,
    existingProperty3Schema,
    existingProperty4Schema,
];
